import { connect } from "react-redux";

import LiveLocation from "./LiveLocation";

import { getLatestLocationList } from "./liveLocationActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  liveLocationListLoading: state.liveLocation.latestLocationListLoading,
  liveLocationList: state.liveLocation.latestLocationList,
});

const mapDispatchToProps = (dispatch) => ({
  getLatestLocationList: (payload) => dispatch(getLatestLocationList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveLocation);
