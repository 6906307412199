import { toHHMMSS } from "../../../utils/helper";
export const getHours = (time) => {
  let hours = time.split(":");
  return `${hours[0]} ${parseInt(hours[0]) <= 1 ? "hour" : "hours"} ${
    hours[1]
  } ${parseInt(hours[1]) <= 1 ? "minute" : "minutes"}`;
};

export const getDuration = (duration) => {
  // let hour = parseInt(duration.split(":")[0]);
  // let minutes = parseInt(duration.split(":")[1]);

  // return `${hour} ${hour < 2 ? "hour" : "hours"} ${minutes} minutes`;
  return getHours(toHHMMSS(duration));
};

export const getDurationInMinutes = (duration) => {
  // let hour = parseInt(duration.split(":")[0]);
  // let minutes = parseInt(duration.split(":")[1]);
  // let time = hour * 60 + minutes;
  const time = Math.floor(duration / 60);

  return time;
};
