import styled from 'styled-components';

export const TaskLocationsTableHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 150px 130px 30% 150px auto;
  grid-column-gap: 20px;
  justify-content: flex-start;
  padding: 0 25px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};

  @media screen and (max-width: 1400px) {
    grid-template-columns: 25% 100px 100px 20% 120px auto;
  }
`;

export const TaskLocationsTableRowContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 150px 130px 30% 150px auto;
  grid-column-gap: 20px;
  justify-content: flex-start;
  padding: 0 25px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media screen and (max-width: 1400px) {
    grid-template-columns: 25% 100px 100px 20% 120px auto;
  }
`;
