import {
  GET_LATEST_LOCATION_LIST,
  GET_LATEST_LOCATION_LIST_SUCCESS,
  GET_LATEST_LOCATION_LIST_FAILURE,
} from "../../../modules/constants";

export const getLatestLocationListRequestHandler = (state, action) => {
  return {
    ...state,
    latestLocationListLoading: true,
  };
};
export const getLatestLocationListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      latestLocationListLoading: false,
      latestLocationList: data.data,
    };
  }
  return {
    ...state,
    latestLocationListLoading: false,
    latestLocationList: [],
  };
};

export const getLatestLocationListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    latestLocationListLoading: false,
    latestLocationList: [],
  };
};

// Action Handlers
export const ACTION_HANDLERS = {
  [GET_LATEST_LOCATION_LIST]: getLatestLocationListRequestHandler,
  [GET_LATEST_LOCATION_LIST_SUCCESS]:
    getLatestLocationListRequestSuccessHandler,
  [GET_LATEST_LOCATION_LIST_FAILURE]:
    getLatestLocationListRequestFailureHandler,
};

const initialState = {
  latestLocationListLoading: false,
  latestLocationList: [],
};

export default function liveLocationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
