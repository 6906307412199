import styled, { css } from 'styled-components';

export const MapAndAlertContainer = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => theme.borderRadii.card};
`;
export const MapTopAlert = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: ${({ height }) => height || '40px'};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.colors.main.grayMedium};
  font-size: ${({ fontSize }) => fontSize || '13px'};
  color: ${({ theme }) => theme.colors.main.white};
  border-top-right-radius: ${({ theme }) => theme.borderRadii.card};
  border-top-left-radius: ${({ theme }) => theme.borderRadii.card};
`;

export const MarkerInfoContainer = styled.div`
  // padding: 15px;
`;

export const TimeInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: max-content;
  padding: 0 15px;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 13px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.borderRadii.button};
`;

export const UserActivityContainer = styled.div`
  display: grid;
  align-content: start;
  gap: 20px;
  margin-bottom: 20px;
`;
export const InfoSectionCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 22px 25px;
  border-radius: 10px;
  border: 1px solid #c2cce1;
`;
export const CardBlock = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 5px;
`;
export const UserInfoAndTimelineContainer = styled.div`
  padding: 30px;
  overflow-y: auto;
`;
export const TimelineContainer = styled.div`
  display: grid;
  grid-template-columns: 75px 285px;
  gap: 5px;
  justify-content: start;
  align-items: start;
  margin: 15px 0 0;
`;
export const TimelineInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 7px;
`;
export const GreenCircle = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #4ab37b;
  margin-left: 5px;
  display: inline-flex;
  align-self: center;
  padding: 4px;
`;
export const TimelineBorder = styled.div`
  width: 2px;
  border-radius: 10px;
  height: 100%;
  background-color: #c2cce1;
  margin: 0 auto;
`;
export const TimelineTextSection = styled.div`
  display: grid;
  gap: 7px;
`;
export const LateTag = styled.span`
  padding: 2px 8px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: 600;
  background-color: #ea9f48;
  font-size: 12px;
  margin-left: 5px;
`;
