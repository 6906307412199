import { useState } from 'react';
import { useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import { CommonImage } from '../../styledComponents/common';
import { getMapCircle } from '../../utils/helper';

const StaticTaskLocationModal = props => {
  const { isOpen, toggle, taskLocation } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (taskLocation && taskLocation.id) {
      setIsLoading(true);

      const baseUrl = `https://maps.googleapis.com/maps/api/staticmap?key=${process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY}&size=640x512&zoom=16&scale=1&language=ar`;
      const markers = `&markers=color:red%7C${taskLocation.latitude},${taskLocation.longitude}`;
      const path = `&path=fillcolor:0x6f7aff33%7Cweight:2%7Ccolor:0x6f7affff${getMapCircle(
        taskLocation.latitude,
        taskLocation.longitude,
        taskLocation.radius,
      )}`;
      const url = `${baseUrl}${markers}${path}`;

      setImageUrl(url);
      setIsLoading(false);
    }
  }, [taskLocation]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      style={{ maxWidth: '640px', maxHeight: '512px' }}
    >
      <ModalBody style={{ padding: `10px` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <CommonImage src={imageUrl} alt="map" />
        )}
      </ModalBody>
    </Modal>
  );
};

export default StaticTaskLocationModal;
