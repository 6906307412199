import {
  SIGN_UP_REQUEST,
  SIGN_UP_RESET,
  CHECK_TOKEN,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function signUp(value) {
  return {
    type: SIGN_UP_REQUEST,
    payload: value,
  };
}

export function resetSignUp() {
  return {
    type: SIGN_UP_RESET,
  };
}

export function checkToken() {
  return {
    type: CHECK_TOKEN
  }
}
