import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const PopupContainer = styled.div``;
export const PopupBackgroundOverlay = styled.div`
  position: fixed;
  display: ${({ popupOpen }) => (popupOpen ? `block` : `none`)};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
`;
export const PopupWrapper = styled.div`
  display: ${({ popupOpen }) => (popupOpen ? `block` : `none`)};
  position: ${({ popupOpen }) => (popupOpen ? `relative` : `absolute`)};
  left: ${({ popupOpen }) => (popupOpen ? `0` : `9999px`)};
  z-index: 2001;
`;
export const PopupContent = styled.div`
  position: absolute;
  width: 100%;
  z-index: inherit;
  margin-bottom: 30px;
`;

const riseUp = keyframes`
  0% {
    bottom: 4px;
  }
  100% {
    bottom: 30px;
  }
`;
export const PopupItemsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  box-shadow: 2px 0px 10px #698ebe4d;
  display: flex;
  align-items: flex-start;
  cursor: auto;
  min-width: 460px;
  height: 320px;
  padding: 0;
  position: absolute;
  right: ${({ sidebarMini }) => (sidebarMini ? `-5px` : `22px`)};
  transform: translateX(100%);
  bottom: 30px;
  // height: auto;
  z-index: 2001;
  animation: 0.35s ${riseUp} ease;
`;

export const BottomSection = styled.div`
  height: 55px;
  margin-top: auto;
`;

export const OrganizationItemsContainer = styled.div`
  display: flex;
  cursor: auto;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding: 15px 0 0;
  height: 320px;
  min-width: 190px;
`;
export const OrgSectionContainer = styled.div`
  height: auto;
  max-height: ${({ height }) => height || "157px"};
`;
export const OrgScrollSection = styled.div`
  height: auto;
  max-height: ${({ height }) => height || "118px"};
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: ${({ theme }) => theme.dropdown.scrollbar.width};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.dropdown.scrollbar.trackBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbHoverBgColor};
  }
`;
export const OrgButtonContainer = styled.div`
  height: 54px;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export const OrgIconDiv = styled.div`
  height: ${({ size }) => size || "34px"};
  width: ${({ size }) => size || "34px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: ${({ selected, theme }) =>
    selected && `2px solid ${theme.colors.main.success}`};
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ selected }) => !selected && "pointer"};
`;
export const OrgText = styled.p`
  margin: 0;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.main.textSecondary : theme.colors.main.labelText};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12.5px")};
  cursor: ${({ selected }) => !selected && "pointer"};
  &:hover {
    color: ${({ hoverColor, selected, theme }) =>
      !selected && (hoverColor ? hoverColor : theme.colors.main.textSecondary)};
  }
`;
export const CreateOrgButton = styled.div`
  height: ${({ size }) => size || "28px"};
  width: ${({ size }) => size || "28px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  background-color: ${({ theme }) => theme.colors.main.white};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 385px;
  background-color: ${({ theme }) => theme.colors.border.secondaryLight};
`;

export const ProfileItemsContainer = styled.div`
  display: flex;
  cursor: auto;
  justify-content: flex-start;
  flex-flow: column nowrap;
  padding: 15px 0 0;
  height: 320px;
  min-width: 270px;
`;
export const ProfileMenuItem = styled.div`
  padding: 6px 5px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin: ${({ margin }) => margin || "0 10px"};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primaryLight};
  }
`;

export const DividerLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.border.secondaryLight};
`;

export const DownloadLink = styled.a`
  margin: 0;
  height: 14px;
  width: 14px;
`;
export const DownloadPlatformIcon = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  vertical-align: super;
  border-radius: 0;
  &:hover {
    filter: brightness(85%);
  }
`;
