import moment from "moment";
import {
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE,
  GET_URL_USAGE_REPORT,
  GET_URL_USAGE_REPORT_SUCCESS,
  GET_URL_USAGE_REPORT_FAILURE,
  GET_APP_USAGE_REPORT,
  GET_APP_USAGE_REPORT_SUCCESS,
  GET_APP_USAGE_REPORT_FAILURE,
  GET_MANUAL_TIME_REPORT,
  GET_MANUAL_TIME_REPORT_SUCCESS,
  GET_MANUAL_TIME_REPORT_FAILURE,
  GET_APP_TIME_INFO_REPORT,
  GET_APP_TIME_INFO_REPORT_SUCCESS,
  GET_APP_TIME_INFO_REPORT_FAILURE,
  GET_REPORT_NOTES,
  GET_REPORT_NOTES_SUCCESS,
  GET_REPORT_NOTES_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleGetTimeActivityReport = (state, action) => {
  return {
    ...state,
    isLoading: true,
    noData: false,
    averageActivity: null,
  };
};
export const handleGetTimeActivityReportSuccess = (state, action) => {
  const {
    payload: { data, startDate, endDate },
  } = action;
  return {
    ...state,
    isLoading: false,
    noData: true,
    averageActivity: { data, startDate, endDate },
  };
};
export const handleGetTimeActivityReportFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    noData: false,
  };
};

export const handleManualTimeRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    noData: false,
  };
};
export const handleManualTimeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  const reportData = JSON.parse(JSON.stringify(data));
  reportData.sort(
    (a, b) =>
      moment(Object.keys(b)[0]).unix() - moment(Object.keys(a)[0]).unix()
  );
  return {
    ...state,
    isLoading: false,
    noData: true,
    manualTimeData: reportData,
  };
};
export const handleManualTimeRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    noData: false,
    manualTimeData: undefined,
  };
};

export const handleUrlUsageRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleUrlUsageRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    urlReportData: data,
  };
};
export const handleUrlUsageRequestFailure = (state, action) => {
  return {
    ...state,
    urlReportData: undefined,
  };
};

export const handleAppUsageRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    noData: false,
  };
};
export const handleAppUsageRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  const appReportData = JSON.parse(JSON.stringify(data));
  appReportData.sort(
    (a, b) => moment(b.working_day).unix() - moment(a.working_day).unix()
  );
  return {
    ...state,
    isLoading: false,
    noData: true,
    appReportData: appReportData,
  };
};
export const handleAppUsageRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    noData: false,
    appReportData: undefined,
  };
};

//App time info
export const handleAppTimeInfoRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleAppTimeInfoRequestSuccess = (state, action) => {
  const {
    payload: { data, startDate, endDate },
  } = action;
  return {
    ...state,
    appTimeData: { data, startDate, endDate },
  };
};
export const handleAppTimeInfoRequestFailure = (state, action) => {
  return {
    ...state,
    appTimeData: undefined,
  };
};

export const handleGetReportNotesRequest = (state, action) => {
  return {
    ...state,
    reportNotesLoading: true,
  };
};
export const handleGetReportNotesRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data && data.results) {
    return {
      ...state,
      reportNotesLoading: false,
      notes: data.results,
    };
  }
  return {
    ...state,
    reportNotesLoading: false,
    notes: [],
  };
};
export const handleGetReportNotesRequestFailure = (state, action) => {
  return {
    ...state,
    reportNotesLoading: false,
    notes: [],
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT]: handleGetTimeActivityReport,
  [GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS]:
    handleGetTimeActivityReportSuccess,
  [GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE]:
    handleGetTimeActivityReportFailure,

  [GET_MANUAL_TIME_REPORT]: handleManualTimeRequest,
  [GET_MANUAL_TIME_REPORT_SUCCESS]: handleManualTimeRequestSuccess,
  [GET_MANUAL_TIME_REPORT_FAILURE]: handleManualTimeRequestFailure,

  [GET_URL_USAGE_REPORT]: handleUrlUsageRequest,
  [GET_URL_USAGE_REPORT_SUCCESS]: handleUrlUsageRequestSuccess,
  [GET_URL_USAGE_REPORT_FAILURE]: handleUrlUsageRequestFailure,

  [GET_APP_USAGE_REPORT]: handleAppUsageRequest,
  [GET_APP_USAGE_REPORT_SUCCESS]: handleAppUsageRequestSuccess,
  [GET_APP_USAGE_REPORT_FAILURE]: handleAppUsageRequestFailure,

  [GET_APP_TIME_INFO_REPORT]: handleAppTimeInfoRequest,
  [GET_APP_TIME_INFO_REPORT_SUCCESS]: handleAppTimeInfoRequestSuccess,
  [GET_APP_TIME_INFO_REPORT_FAILURE]: handleAppTimeInfoRequestFailure,

  [GET_REPORT_NOTES]: handleGetReportNotesRequest,
  [GET_REPORT_NOTES_SUCCESS]: handleGetReportNotesRequestSuccess,
  [GET_REPORT_NOTES_FAILURE]: handleGetReportNotesRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  reportNotesLoading: false,
  noData: false,
  averageActivity: null,
  manualTimeData: [],
  appReportData: [],
  urlReportData: [],
  appTimeData: [],
  notes: [],
};

export default function reportReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
