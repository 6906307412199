import {
  ActivityBarTrack,
  ActivityBarTrain,
} from "../../styledComponents/common";

const LinearActivityBar = ({
  percent,
  marginTop,
  height,
  mobileHeight,
  width,
  mobileWidth,
  red,
  yellow,
  green,
  borderRadius,
}) => (
  <ActivityBarTrack
    percent={percent}
    marginTop={marginTop}
    width={width}
    mobileWidth={mobileWidth}
    height={height}
    mobileHeight={mobileHeight}
    borderRadius={borderRadius}
    red={red}
    yellow={yellow}
    green={green}>
    <ActivityBarTrain
      percent={percent}
      height={height}
      mobileHeight={mobileHeight}
      red={red}
      yellow={yellow}
      green={green}
      borderRadius={borderRadius}
    />
  </ActivityBarTrack>
);
export default LinearActivityBar;
