import styled, { css } from "styled-components";

// ----- ACTIVITY > SCREENSHOTS > SCREENSHOTSFILTER

export const FilterSection = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "1fr 1fr"};
  grid-column-gap: 35px;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};

  @media screen and (max-width: 1400px) {
    grid-column-gap: 18px;
  }
`;

export const FilterInnerSection = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "1fr 1fr auto"};
  grid-column-gap: ${({ columnGap }) => columnGap || "35px"};
  justify-content: space-between;
  /* align-content: center; */
  max-width: 600px;

  @media screen and (max-width: 1400px) {
    grid-column-gap: 18px;
  }
`;

// ----- USER ACTIVITY INFO

export const OuterBox = styled.div`
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  padding: 20px 30px;
  margin: 20px 0 10px;
`;

export const UserActivitySection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 35px;
  justify-content: space-between;
  max-width: 1050px;
`;

// ----- CARDS LIST -----

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: 15px;
  padding-left: 18px;
  border-left: 3px solid rgba(194, 204, 225, 0.3);
  margin: 12px 0 12px 7px;
`;

export const RoundCircle = styled.div`
  width: ${({ size }) => size || "17px"};
  height: ${({ size }) => size || "17px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
`;

// ----- SINGLE CARD -----

export const SingleCardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  padding: 15px 10px;
  display: grid;
  grid-row-gap: 10px;
  /* grid-template-rows: auto 155px auto auto; */
`;
export const CardUpperText = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 20px;
  padding-left: 8px;
`;
export const NoteBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 0 6px;
  background-color: ${({ theme }) => theme.button.blueButton.bgColor};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.button.blueButton.hoverBgColor};
  }
`;
export const NoteText = styled.div`
  color: ${({ theme }) => theme.button.blueButton.textColor};
  font-size: 11.5px;
  font-weight: ${({ theme }) => theme.button.blueButton.fontWeight};
`;

export const TimeMonitorSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin-bottom: ${({ main }) => main && `8px`};
  padding: ${({ main }) => main && `0 8px`};
  grid-column-gap: ${({ columnGap }) => columnGap || "3px"};
  align-items: center;
`;

export const ActivityProductiveSection = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  grid-row-gap: 10px;
`;
export const NoteDeleteSection = styled.div`
  display: none;
  grid-template-columns: ${({ columns }) => columns || "auto 1fr"};
  justify-content: space-between;
  grid-column-gap: 10px;
  align-content: center;
`;
export const SSimg = styled.img`
  border: 1px solid #c2cce1;
  border-radius: 6px;
  ${({ screenshotBlur }) =>
    screenshotBlur &&
    css`
      filter: blur(2px);
    `}
`;

export const HoverSection = styled.div`
  display: grid;
  height: 50px;
  margin: 0 20px;
  &:hover ${NoteDeleteSection} {
    display: grid;
  }
  &:hover ${ActivityProductiveSection} {
    display: none;
  }
`;

export const NoSceenshotTooltip = styled.div`
  position: absolute;
  bottom: 20%;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-content: center;
  grid-column-gap: 10px;
  padding: ${(props) => props.padding && props.padding};
  margin-top: ${(props) => props.marginTop && props.marginTop};
`;
export const RoundQues = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  color: ${({ theme }) => theme.colors.main.white};
  background-color: ${({ theme }) => theme.colors.border.secondary};
  font-size: 12px;
  cursor: pointer;
`;

export const ToolTipContainer = styled.div`
  position: absolute;
  left: 40px;
  top: -20px;
  padding: 20px 0 5px;
  border-radius: 6px;
  background-color: white;
  width: 420px;
  z-index: 5;
  box-shadow: 0px 10px 90px #00000033;
`;

export const ToolTip = styled.div`
  display: grid;
  grid-row-gap: 10px;
  position: relative;
`;
export const ToolTipTriangle = styled.div`
  position: absolute;
  top: -3px;
  left: -18px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 25px solid white;
`;
export const CollapseText = styled.div`
  max-height: ${({ collapse, index }) => (collapse === index ? `500px` : `0`)};
  overflow: ${({ collapse, index }) =>
    collapse === index ? `visible` : `hidden`};
  transition: max-height 0.8s ease;
  font-size: ${({ fontSize }) => fontSize || "13px"};
  color: ${({ theme }) => theme.colors.main.textSecondary};
  padding: 0 20px;
  border-bottom: 1px solid #f0f3f7;
  background-color: ${({ theme }) => theme.colors.main.white};
  ${({ collapse, index }) =>
    collapse === index &&
    css`
      padding-bottom: 8px;
    `}
  &:last-child {
    border-bottom: none;
  }
`;
export const CollapseQues = styled.div`
  display: grid;
  grid-template-columns: auto 30px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;
  background-color: white;
`;
export const CollapseRotate = styled.div`
  transform: ${(props) =>
    props.collapse === props.index ? `rotate(0deg)` : `rotate(-90deg)`};
  transition: transform 0.8s ease;
`;
export const AppsTimeSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`;
export const AppTimeItem = styled.div`
  display: grid;
  grid-row-gap: 3px;
  text-align: center;
  background-color: ${({ index, theme }) =>
    index === 1
      ? theme.colors.main.primaryLight
      : index === 2
      ? `#FBF3DA`
      : `#ECF0FC`};
  border-radius: ${({ index }) =>
    index === 1 ? `6px 0 0 6px` : index === 2 ? `0` : `0 6px 6px 0`};
  padding: 8px 25px;
`;
export const AppUrlCard = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-column-gap: 15px;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  @media (max-width: 1366px) {
    padding: 12px;
    grid-column-gap: 12px;
  }
`;
