import {
  GET_ROUTE_MAP_LOCATIONS,
  GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST,
  GET_TASK_CHECK_IN_OUT_LIST,
} from "../../../modules/constants";

export function getRouteMapLocations(payload) {
  return {
    type: GET_ROUTE_MAP_LOCATIONS,
    payload,
  };
}

export function getSingleEmployeeClockInOutList(payload) {
  return {
    type: GET_SINGLE_EMPLOYEE_CLOCK_IN_OUT_LIST,
    payload,
  };
}

export function getTaskCheckInOutList(payload) {
  return {
    type: GET_TASK_CHECK_IN_OUT_LIST,
    payload,
  };
}
