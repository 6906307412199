//Effects
import { all, put, takeLatest, select } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE,
  GET_MANUAL_TIME_REPORT,
  GET_MANUAL_TIME_REPORT_SUCCESS,
  GET_MANUAL_TIME_REPORT_FAILURE,
  GET_URL_USAGE_REPORT,
  GET_URL_USAGE_REPORT_SUCCESS,
  GET_URL_USAGE_REPORT_FAILURE,
  GET_APP_USAGE_REPORT,
  GET_APP_USAGE_REPORT_SUCCESS,
  GET_APP_USAGE_REPORT_FAILURE,
  GET_APP_TIME_INFO_REPORT,
  GET_APP_TIME_INFO_REPORT_SUCCESS,
  GET_APP_TIME_INFO_REPORT_FAILURE,
  GET_REPORT_NOTES,
  GET_REPORT_NOTES_SUCCESS,
  GET_REPORT_NOTES_FAILURE,
} from "../../modules/constants";

export function* handleGetTasks({ payload }) {
  // const store = yield select();
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/average-activity/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS,
      payload: {
        data: response.data,
        startDate: payload.start_date,
        endDate: payload.end_date,
      },
    });
  } catch (err) {
    yield put({
      type: GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleManualTimeReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/manual-timesheet-reports/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_MANUAL_TIME_REPORT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_MANUAL_TIME_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleUrlUsageReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/url-reports/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_URL_USAGE_REPORT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_URL_USAGE_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleAppUsageReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/app-reports/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_APP_USAGE_REPORT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_APP_USAGE_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleAppTimeInfoReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/app-time-info/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_APP_TIME_INFO_REPORT_SUCCESS,
      payload: {
        data: response.data,
        startDate: payload.start_date,
        endDate: payload.end_date,
      },
    });
  } catch (err) {
    yield put({
      type: GET_APP_TIME_INFO_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* getReportNotesRequest({ payload }) {
  if (!payload) return;
  const { organization_id, date, user_id } = payload;
  try {
    const url = `${organization_id}/reports/notes/?date=${date}&user_id=${user_id}`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_REPORT_NOTES_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_REPORT_NOTES_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT, handleGetTasks),
    takeLatest(GET_MANUAL_TIME_REPORT, handleManualTimeReport),
    takeLatest(GET_URL_USAGE_REPORT, handleUrlUsageReport),
    takeLatest(GET_APP_USAGE_REPORT, handleAppUsageReport),
    takeLatest(GET_APP_TIME_INFO_REPORT, handleAppTimeInfoReport),
    takeLatest(GET_REPORT_NOTES, getReportNotesRequest),
  ]);
}
