import styled from "styled-components";

export const TaskFilterContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns || `auto 230px auto 240px 130px`};
  justify-content: start;
  grid-column-gap: 15px;
`;

export const TabButtonContainer = styled.div`
  display: grid;
  width: ${({ width }) => width};
  grid-template-columns: ${({ columns }) => columns || `auto auto auto`};
  justify-content: start;
  align-items: center;
  grid-column-gap: 3px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.main.white};
  margin: ${({ margin }) => margin};
`;
export const TabSearchGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "auto auto auto"};
  justify-content: start;
  @media (max-width: 767px) {
    grid-template-columns: auto;
    justify-content: start;
    grid-row-gap: 10px;
  }
`;
export const ButtonSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 22px 25px;
  border-radius: 10px;
`;

export const ReviewSection = styled.div`
  display: grid;
  grid-template-columns: 600px auto;
  justify-content: space-between;
  margin: 0 -20px 0 -20px;
  padding: 10px;
  gap: 15px;
`;
export const searchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ height, theme }) => height || theme.input.minHeight};
  width: ${({ width }) => width || "100%"};
  padding: 3px 3px 3px 10px;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.input.bgColor.default};
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor ? borderColor : theme.input.borderColor.default};
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus-within {
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;
export const SearchBoxSection = styled.div`
  display: grid;
  grid-row-gap: ${({ gap }) => gap || "2px"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
`;

export const SearchWithButtonContainerForSearchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ height, theme }) => height || theme.input.minHeight};
  width: ${({ width }) => width || "100%"};
  padding: 3px 10px 3px 10px;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.input.bgColor.default};
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor ? borderColor : theme.input.borderColor.default};
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus-within {
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;

export const SearchWithButtonInputNew = styled.input`
  width: ${({ width }) => width || "calc(100% - 63px)"};
  background-color: ${({ theme }) => theme.colors.main.white};
  border: none;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:focus {
    outline: none;
  }
`;
